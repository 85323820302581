<template>
  <div class="newsDetails">
    <div class="bgheader"></div>
    <div class="container">
      <div class="container-box">
        <div class="left">
          <div class="Path">首页>{{ menuTwo }}>{{ curnews.news_title }}</div>
          <div class="title">{{ curnews.news_title }}</div>
          <div class="sign"> {{ curnews.author }}</div>
          <div class="content" v-html="curnews.detail_content">
          </div>
        </div>
        <div class="right">
          <div class="right-list">
            <div class="title">
              热门新闻
              <div class="axis"></div>
            </div>
            <div class="right-box">
              <div class="text">九州通加码投入升级冷链物流，助力疫苗高效运输</div>
              <div class="tx">九州云智 | 2023-05-26</div>
            </div>
            <div class="right-box">
              <div class="text">九州通加码投入升级冷链物流，助力疫苗高效运输</div>
              <div class="tx">九州云智 | 2023-05-26</div>
            </div>
            <div class="right-box">
              <div class="text">九州通加码投入升级冷链物流，助力疫苗高效运输</div>
              <div class="tx">九州云智 | 2023-05-26</div>
            </div>
          </div>
          <!-- <img class="Ricon" src="../assets/image/news/xinlang.png" alt="" /> -->
          <img class="Ricon" style="margin-left: 0.26rem;" src="../assets/image/news/weixin.png" alt="" />
        </div>
      </div>
      <div class="container_bottom">
        <div class="last bottom-box" style="margin-right: 0.64rem;" v-if="beforenews" @click="changenews(beforenews)">
          <div class="box1">上一篇
            <div class="axis"></div>
          </div>
          <div class="text">{{ beforenews.news_title }}</div>
          <div class="tx">九州云智 | {{ beforenews.create_date }}</div>
        </div>
        <div class="next bottom-box" v-if="afternews" @click="changenews(afternews)">
          <div class="box1">下一篇
            <div class="axis"></div>
          </div>
          <div class="text">{{ afternews.news_title }}</div>
          <div class="tx">九州云智 | {{ afternews.create_date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      curnews: {},
      beforenews: null,
      afternews: null
    }
  },
  computed: {
    menuTwo () {
      const obj = {
        'news': '新闻资讯',
        'case': '案例中心'
      }
      return obj[this.$route.query.type] || '新闻资讯'
    }
  },
  methods: {
    changenews (news) {
      this.$route.query.id = news.news_id
      this.queryNews()
      document.documentElement.scrollTop = 0
    },
    async queryNews () {
      let newsId = this.$route.query.id
      const { code, data, message } = await this.http.service({
        url: '/tdp/data/gateway/v2/news_search_new/select',   //案例中心详情
        data: {
          "news_type": this.$route.query.type
        }
      })
      if (code === 200) {
        data.forEach((element, index) => {
          if (element.news_id == newsId) {
            this.curnews = element
            // this.curnews.detail_content = JSON.parse(this.curnews.detail_content)
            this.beforenews = (index - 1) === -1 ? null : data[(index - 1)]
            this.afternews = (index + 1) === data.length ? null : data[(index + 1)]
          }
        })
      } else {
        this.$message.error(message)
      }
    }
  },
  mounted () {
    this.queryNews()
  }
}
</script>

<style lang='scss' scoped>
.newsDetails {
  .bgheader {
    height: 1.02rem;
    background: #0560D6;
  }

  .container {
    width: 100%;
    padding: 0 2.6rem;

    .container-box {
      background: #FFFFFF;
      display: flex;

      .left {
        width: 9.96rem;

        .Path {
          margin-top: 0.24rem;
          height: 0.26rem;
          font-size: 0.14rem;
          font-family: MicrosoftYaHei;
          color: #565E66;
          line-height: 0.26rem;
        }

        .title {
          margin-top: 0.24rem;
          height: 0.47rem;
          font-size: 0.36rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #0A121F;
          line-height: 0.47rem;
        }

        .sign {
          margin-top: 0.5rem;
          height: 0.26rem;
          font-size: 0.14rem;
          font-family: MicrosoftYaHei;
          color: #9AA3B2;
          line-height: 0.26rem;
        }

        .content {
          margin-top: 0.32rem;
          // height: 9rem;
          font-size: 0.14rem;
          font-family: MicrosoftYaHei;
          color: #0A121F;
          line-height: 0.28rem;
          position: relative;

          p strong {
            font-size: 0.24rem !important;
          }

          img {
            position: absolute;
            display: block;
            width: 6rem;
            height: 4rem;
            margin-top: 0.32rem;
            left: 50%;
            margin-left: -3rem;
          }
        }
      }

      .right {
        margin-left: 0.72rem;

        .right-list {
          margin-top: 1rem;
          width: 3.32rem;
          height: 4.41rem;
          padding: 0.24rem 0.32rem;
          border: 0.01rem solid #9AA3B2;
          background: #FFFFFF;

          .title {
            width: 0.8rem;
            height: 0.26rem;
            font-size: 0.2rem;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #0A121F;
            line-height: 0.26rem;

            .axis {
              width: 0.8rem;
              height: 0.03rem;
              background: #1E76F7;
            }
          }

          .right-box {
            height: 0.98rem;
            border-bottom: 0.01rem solid #9AA3B2;

            .text {
              margin-top: 0.16rem;
              font-size: 0.14rem;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
              color: #0A121F;
              line-height: 0.26rem;
            }

            .tx {
              margin-top: 0.08rem;
              height: 0.26rem;
              font-size: 0.14rem;
              font-family: MicrosoftYaHei;
              color: #9AA3B2;
              line-height: 0.26rem;
            }
          }
        }

        .Ricon {
          margin-top: 0.32rem;
          width: 0.48rem;
          height: 0.48rem;
        }
      }

    }

    .container_bottom {
      display: flex;
      margin-bottom: 0.1rem;

      .bottom-box {
        display: flex;
        flex-direction: column;
        width: 4.66rem;
        height: 2.07rem;
        background: #FFFFFF;
        border: 0.01rem solid #9AA3B2;
        padding: 0 0.32rem;
        cursor: pointer;

        .box1 {
          width: 0.6rem;
          height: 0.26rem;
          font-size: 0.2rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #0A121F;
          line-height: 0.26rem;
          margin-top: 0.24rem;

          .axis {
            margin-top: 0.08rem;
            width: 0.6rem;
            height: 0.03rem;

          }
        }

        .text {
          margin-top: 0.16rem;
          height: 0.72rem;
          font-size: 0.14rem;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #0A121F;
          line-height: 0.26rem;
        }

        .tx {
          margin-top: 0.4rem;
          height: 0.14rem;
          font-size: 0.14rem;
          font-family: MicrosoftYaHei;
          color: #9AA3B2;
          line-height: 0.14rem;
        }
      }

      .bottom-box:hover {
        background: #FFFFFF;
        box-shadow: 0rem 0rem 0.16rem 0rem #D1E0F5;
      }

      .last .box1 .axis {
        background: #1E76F7;
      }

      .next {
        // margin-left: 0.64rem;

        .box1 .axis {
          background: #1EE3F7
        }
      }
    }
  }
}
</style>
